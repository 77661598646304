@import "figma-colors";

// scss-docs-start gray-color-variables
$white: $white-color !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: $black-color !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue: $primary-color-500 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: $secondary-color-500 !default;
$red: #dc3545 !default;
$orange: $orange-color !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "black": $black,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800
) !default;
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary: $blue !default;
$secondary: $primary-color-700 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $primary-color-900 !default;
$dark: $primary-color-100 !default;
// scss-docs-end theme-color-variables
