// Breakpoint helper
body.dev {
    &:before {
        content: "";
        position: fixed;
        z-index: 99999;
        color: white;
        left: 0;
        top: 0;
        background-color: transparent;
        padding: 2px 10px;
        text-transform: uppercase;
        font-size: 12px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial,
            sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    }
}

@include media-breakpoint-up(xxl) {
    body.dev {
        &:before {
            content: "xxl";
            background-color: rgb(116, 49, 223);
        }
    }
}

@include media-breakpoint-down(xxl) {
    body.dev {
        &:before {
            content: "xl";
            background-color: rgb(230, 59, 219);
        }
    }
}

@include media-breakpoint-down(xl) {
    body.dev {
        &:before {
            content: "lg";
            background-color: red;
        }
    }
}

@include media-breakpoint-down(lg) {
    body.dev {
        &:before {
            content: "md";
            background-color: violet;
        }
    }
}

@include media-breakpoint-down(md) {
    body.dev {
        &:before {
            content: "sm";
            background-color: orange;
        }
    }
}

@include media-breakpoint-down(sm) {
    body.dev {
        &:before {
            content: "xs";
            background-color: blue;
        }
    }
}
