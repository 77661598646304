// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
    0: 0,
    0d125: $spacer * 0.125,
    0d25: $spacer * 0.25,
    0d375: $spacer * 0.375,
    0d5: $spacer * 0.5,
    0d625: $spacer * 0.625,
    0d75: $spacer * 0.75,
    0d875: $spacer * 0.875,
    1: $spacer * 1,
    1d: $spacer * 1,
    1d0: $spacer * 1,
    1d125: $spacer * 1.125,
    1d25: $spacer * 1.25,
    1d375: $spacer * 1.375,
    1d5: $spacer * 1.5,
    1d625: $spacer * 1.625,
    1d75: $spacer * 1.75,
    1d875: $spacer * 1.875,
    2: $spacer * 2,
    2d: $spacer * 2,
    2d0: $spacer * 2,
    2d125: $spacer * 2.125,
    2d25: $spacer * 2.25,
    2d375: $spacer * 2.375,
    2d5: $spacer * 2.5,
    2d625: $spacer * 2.625,
    2d75: $spacer * 2.75,
    2d875: $spacer * 2.875,
    3: $spacer * 3,
    3d: $spacer * 3,
    3d0: $spacer * 3,
    3d125: $spacer * 3.125,
    3d25: $spacer * 3.25,
    3d375: $spacer * 3.375,
    3d5: $spacer * 3.5,
    3d625: $spacer * 3.625,
    3d75: $spacer * 3.75,
    3d875: $spacer * 3.875,
    4: $spacer * 4,
    4d: $spacer * 4,
    4d0: $spacer * 4,
    4d125: $spacer * 4.125,
    4d25: $spacer * 4.25,
    4d375: $spacer * 4.375,
    4d5: $spacer * 4.5,
    4d625: $spacer * 4.625,
    4d75: $spacer * 4.75,
    4d875: $spacer * 4.875,
    5: $spacer * 5,
    5d: $spacer * 5,
    5d0: $spacer * 5,
    5d125: $spacer * 5.125,
    5d25: $spacer * 5.25,
    5d375: $spacer * 5.375,
    5d5: $spacer * 5.5,
    5d625: $spacer * 5.625,
    5d75: $spacer * 5.75,
    5d875: $spacer * 5.875,
    6: $spacer * 6,
    6d: $spacer * 6,
    6d0: $spacer * 6,
    6d125: $spacer * 6.125,
    6d25: $spacer * 6.25,
    6d375: $spacer * 6.375,
    6d5: $spacer * 6.5,
    6d625: $spacer * 6.625,
    6d75: $spacer * 6.75,
    6d875: $spacer * 6.875,
    7: $spacer * 7,
    7d: $spacer * 7,
    7d0: $spacer * 7,
    7d125: $spacer * 7.125,
    7d25: $spacer * 7.25,
    7d375: $spacer * 7.375,
    7d5: $spacer * 7.5,
    7d625: $spacer * 7.625,
    7d75: $spacer * 7.75,
    7d875: $spacer * 7.875,
    8: $spacer * 8,
    8d: $spacer * 8,
    8d0: $spacer * 8,
    8d125: $spacer * 8.125,
    8d25: $spacer * 8.25,
    8d375: $spacer * 8.375,
    8d5: $spacer * 8.5,
    8d625: $spacer * 8.625,
    8d75: $spacer * 8.75,
    8d875: $spacer * 8.875,
    9: $spacer * 9,
    9d: $spacer * 9,
    9d0: $spacer * 9,
    9d125: $spacer * 9.125,
    9d25: $spacer * 9.25,
    9d375: $spacer * 9.375,
    9d5: $spacer * 9.5,
    9d625: $spacer * 9.625,
    9d75: $spacer * 9.75,
    9d875: $spacer * 9.875,
    10: $spacer * 10,
    10d: $spacer * 10,
    10d0: $spacer * 10,
    10d125: $spacer * 10.125,
    10d25: $spacer * 10.25,
    10d375: $spacer * 10.375,
    10d5: $spacer * 10.5,
    10d625: $spacer * 10.625,
    10d75: $spacer * 10.75,
    10d875: $spacer * 10.875,
    11: $spacer * 11,
    11d: $spacer * 11,
    11d0: $spacer * 11,
    12: $spacer * 12,
    12d: $spacer * 12,
    12d0: $spacer * 12,
    13: $spacer * 13,
    13d: $spacer * 13,
    13d0: $spacer * 13,
    14: $spacer * 14,
    14d: $spacer * 14,
    14d0: $spacer * 14,
    15: $spacer * 15,
    15d: $spacer * 15,
    15d0: $spacer * 15,
    16: $spacer * 16,
    16d: $spacer * 16,
    16d0: $spacer * 16,
    17: $spacer * 17,
    17d: $spacer * 17,
    17d0: $spacer * 17,
    18: $spacer * 18,
    18d: $spacer * 18,
    18d0: $spacer * 18,
    19: $spacer * 19,
    19d: $spacer * 19,
    19d0: $spacer * 19,
    20: $spacer * 20,
    20d: $spacer * 20,
    20d0: $spacer * 20
) !default;
// scss-docs-end spacer-variables-maps
