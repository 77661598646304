.section-howto {
    .company-name-icon {
        max-width: 987px;
        width: 100%;
        height: auto;
    }

    h4,
    p {
        margin: 0;
    }

    .bullet {
        width: 75px;
        min-width: 75px;
        max-width: 75px;
        height: 75px;
        border-radius: 100%;
        z-index: 1;
    }

    .icon-1,
    .icon-2 {
        width: 258px;
        height: 30px;
    }

    .icon-1 {
        left: 20%;
    }

    .icon-2 {
        right: 20%;
        top: 16%;
    }
}

// XL
@include media-breakpoint-down(xxl) {
    .section-howto {
        .company-name-icon {
            max-width: 856px;
        }

        .icon-1,
        .icon-2 {
            width: 194px;
            height: 26px;
        }

        .icon-1 {
            left: 23%;
        }

        .icon-2 {
            right: 23%;
            top: 16%;
        }
    }
}

// LG
@include media-breakpoint-down(xl) {
    .section-howto {
        .bullet {
            width: 64px;
            min-width: 64px;
            max-width: 64px;
            height: 64px;
            border-radius: 100%;
            font-size: 32px;
        }

        .container-sm {
            width: 39%;
        }

        .steps {
            width: 57%;
        }

        .icon-3 {
            width: 40px;
            height: 200px;
            left: 11px;
            top: 31px;
        }
    }
}

// MD
@include media-breakpoint-down(lg) {
    .section-howto {
        .bullet {
            width: 58px;
            min-width: 58px;
            max-width: 58px;
            height: 58px;
            border-radius: 100%;
            font-size: 29px;
        }

        .container-sm {
            width: 100%;
        }

        .steps {
            width: 75%;
        }

        .icon-3 {
            width: 40px;
            height: 190px;
            left: 8px;
            top: 53px;
        }
    }
}

// SM
@include media-breakpoint-down(md) {
    .section-howto {
        .bullet {
            width: 50px;
            min-width: 50px;
            max-width: 50px;
            height: 50px;
            border-radius: 100%;
            font-size: 22px;
        }

        .steps {
            width: 100%;
        }

        .icon-3 {
            width: 35px;
            height: 189px;
            left: 8px;
            top: 38px;
        }
    }
}

// XS
@include media-breakpoint-down(sm) {
    //
}
