// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif: "Noto Sans", sans-serif !default;
// stylelint-enable value-keyword-case

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.25 !default;

$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 2 !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;
// scss-docs-end font-variables

// scss-docs-start headings-variables
$headings-margin-bottom: $spacer * 0.5 !default;
$headings-font-family: "GeologicaRegular" !default;
$headings-line-height: 1.2 !default;
$headings-color: $black-color !default;
// scss-docs-end headings-variables
