.modal-backdrop.show {
    opacity: 1;
    background-color: #0e388278;
    backdrop-filter: blur(5px);
}

.modal-dialog-md {
    max-width: 640px;
}

.modal-content {
    border-radius: 20px;
    border-width: 0;
}

.modal-header,
.modal-body,
.modal-footer {
    padding: 24px;
}

.form-label,
.form-check-label {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 600;
    color: #344054;
}

.form-control {
    border-radius: 8px;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #667085;
    font-family: "Noto Sans", sans-serif;
    padding: 12px 14px;
    border-width: 1px;
    border-color: $stroke-color;
}

.form-check {
    display: flex;
    flex-flow: row;
    align-items: start;
}

.form-check-input {
    width: 24px;
    height: 24px;

    &:checked {
        background-color: $primary-color-200;
        border-color: $primary-color-200;
    }
}

.error-message {
    background: $primary-color-200;
    display: inline-block;
    color: white;
    border-radius: 4px;
    padding: 1px 4px;
    margin-top: 6px;
    font-size: 14px;
}

.bd-callout-info {
    --bd-callout-color: var(--bs-info-text-emphasis);
    --bd-callout-bg: var(--bs-info-bg-subtle);
    --bd-callout-border: var(--bs-info-border-subtle);
}

.bd-callout {
    --bs-link-color-rgb: var(--bd-callout-link);
    --bs-code-color: var(--bd-callout-code-color);
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    color: var(--bd-callout-color, inherit);
    background-color: var(--bd-callout-bg, var(--bs-gray-100));
    border-left: 0.25rem solid var(--bd-callout-border, var(--bs-gray-300));
}

// XL
@include media-breakpoint-down(xxl) {
    //
}

// LG
@include media-breakpoint-down(xl) {
    //
}

// MD
@include media-breakpoint-down(lg) {
    .modal-dialog-md {
        margin: 0;
        max-width: 100%;
    }

    .modal-content {
        border-radius: 0;
        min-height: 100vh;
    }
}

// SM
@include media-breakpoint-down(md) {
    //
}

// XS
@include media-breakpoint-down(sm) {
    //
}
