.section-cta {
    .container {
        img {
            width: 294px;
            height: 205px;
        }

        .content {
            min-width: 633px;
        }
    }
}

// XL
@include media-breakpoint-down(xxl) {
    //
}

// LG
@include media-breakpoint-down(xl) {
    .section-cta {
        .container {
            .content {
                min-width: auto;
            }

            img {
                width: 245px;
                height: auto;
            }
        }
    }
}

// MD
@include media-breakpoint-down(lg) {
    .section-cta {
        .container {
            .content {
                width: 100%;
                text-align: center;
            }
        }
    }
}

// SM
@include media-breakpoint-down(md) {
    .section-cta {
        .container {
            .content {
                text-align: left;
            }
        }

        img {
            width: 113px;
            height: auto;
        }
    }
}

// XS
@include media-breakpoint-down(sm) {
    //
}
