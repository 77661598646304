.section-hero {
    min-height: 939px;

    .btn-ouline-primary-color-200 {
        &:hover {
            background-color: $secondary-color-500 !important;
            border-color: $secondary-color-500 !important;
            color: #fff !important;

            .icon {
                filter: invert(1);
            }
        }
    }
}

// XL
@include media-breakpoint-down(xxl) {
    //
}

// LG
@include media-breakpoint-down(xl) {
    //
    .section-hero {
        min-height: 809px;
    }
}

// MD
@include media-breakpoint-down(lg) {
    .section-hero {
        min-height: 100vh;
    }
}

// SM
@include media-breakpoint-down(md) {
    .section-hero {
        background-image: url(../images/hero/bg.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: -128px;

        h1 {
            color: #fff !important;
        }

        .btn-ouline-primary-color-200 {
            background-color: #fff !important;
        }
    }
}

// XS
@include media-breakpoint-down(sm) {
    //
}
