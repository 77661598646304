@import "figma-colors";

// Text
.text-primary-color-100 {
    color: $primary-color-100 !important;
}

.text-primary-color-200 {
    color: $primary-color-200 !important;
}

.text-primary-color-300 {
    color: $primary-color-300 !important;
}

.text-primary-color-400 {
    color: $primary-color-400 !important;
}

.text-primary-color-500 {
    color: $primary-color-500 !important;
}

.text-primary-color-600 {
    color: $primary-color-600 !important;
}

.text-primary-color-700 {
    color: $primary-color-700 !important;
}

.text-primary-color-800 {
    color: $primary-color-800 !important;
}

.text-primary-color-900 {
    color: $primary-color-900 !important;
}

.text-secondary-color-200 {
    color: $secondary-color-200 !important;
}

.text-primary-color-250 {
    color: $primary-color-250 !important;
}

.text-secondary-color-500 {
    color: $secondary-color-500 !important;
}

.text-tertiary-color-200 {
    color: $tertiary-color-200 !important;
}

.text-orange-color {
    color: $orange-color !important;
}

.text-black-color {
    color: $black-color !important;
}

// Border
.border-primary-color-100 {
    border-color: $primary-color-100 !important;
}

.border-primary-color-200 {
    border-color: $primary-color-200 !important;
}

.border-primary-color-300 {
    border-color: $primary-color-300 !important;
}

.border-primary-color-400 {
    border-color: $primary-color-400 !important;
}

.border-primary-color-500 {
    border-color: $primary-color-500 !important;
}

.border-primary-color-600 {
    border-color: $primary-color-600 !important;
}

.border-primary-color-700 {
    border-color: $primary-color-700 !important;
}

.border-primary-color-800 {
    border-color: $primary-color-800 !important;
}

.border-primary-color-900 {
    border-color: $primary-color-900 !important;
}

.border-secondary-color-500 {
    border-color: $secondary-color-500 !important;
}

.border-stroke-color {
    border-color: $stroke-color !important;
}

// Button
.btn-primary-color-100 {
    background-color: $primary-color-100 !important;
    border-color: $primary-color-100 !important;
    color: #fff !important;

    &:hover {
        background-color: $secondary-color-500 !important;
        border-color: $secondary-color-500 !important;
    }
}

.btn-primary-color-200 {
    background-color: $primary-color-200 !important;
    border-color: $primary-color-200 !important;
    color: #fff !important;

    &:hover {
        background-color: $secondary-color-500 !important;
        border-color: $secondary-color-500 !important;
    }
}

.btn-primary-color-300 {
    background-color: $primary-color-300 !important;
    border-color: $primary-color-300 !important;
    color: #fff !important;

    &:hover {
        background-color: $secondary-color-500 !important;
        border-color: $secondary-color-500 !important;
    }
}

.btn-primary-color-400 {
    background-color: $primary-color-400 !important;
    border-color: $primary-color-400 !important;
    color: #fff !important;

    &:hover {
        background-color: $secondary-color-500 !important;
        border-color: $secondary-color-500 !important;
    }
}

.btn-primary-color-500 {
    background-color: $primary-color-500 !important;
    border-color: $primary-color-500 !important;
    color: #fff !important;

    &:hover {
        background-color: $secondary-color-500 !important;
        border-color: $secondary-color-500 !important;
    }
}

.btn-ouline-primary-color-200 {
    background-color: transparent !important;
    border-color: $primary-color-200 !important;
    color: $primary-color-200 !important;

    &:hover {
        background-color: $primary-color-200 !important;
        border-color: $primary-color-200 !important;
        color: #fff !important;

        .icon {
            filter: invert(1);
        }
    }
}

// Background
.bg-primary-color-700 {
    background-color: $primary-color-700 !important;
}

.bg-secondary-color-700 {
    background-color: $secondary-color-700 !important;
}

.bg-tertiary-color-700 {
    background-color: $tertiary-color-700 !important;
}

.bg-quaternary-color-700 {
    background-color: $quaternary-color-700 !important;
}
