@import "figma-colors";

footer {
    color: $primary-color-800;

    .logo-white {
        width: 256px;
        height: 42px;
    }

    a {
        color: $primary-color-700;

        &:hover {
            color: $primary-color-600;
            text-decoration: none;
        }
    }

    span {
        font-size: 14px;
        line-height: 24px;
        color: $primary-color-600;
        font-weight: 600;
        text-transform: uppercase;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            font-size: 16px;
            line-height: 24px;

            a {
                color: $primary-color-800;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .border-visible {
        border-color: transparent !important;
    }
}

// XL
@include media-breakpoint-down(xxl) {
    //
}

// LG
@include media-breakpoint-down(xl) {
    footer {
        .border-visible {
            border-color: $primary-color-300 !important;
        }
    }
}

// MD
@include media-breakpoint-down(lg) {
    //
}

// SM
@include media-breakpoint-down(md) {
    //
}

// XS
@include media-breakpoint-down(sm) {
    //
}
