// XXL (default)
h1 {
    font-size: 40px;
    line-height: 54px;
    text-transform: uppercase;
    font-family: "GeologicaLight";

    &.lead {
        font-size: 60px;
        line-height: 72px;
        font-family: "GeologicaThin";
    }
}

h2 {
    font-size: 36px;
    line-height: 42px;
    font-family: "GeologicaRegular";
}

h3 {
    font-size: 32px;
    line-height: 38px;
    font-family: "GeologicaMedium";
}

h4 {
    font-size: 26px;
    line-height: 32px;
    font-family: "GeologicaMedium";
}

h5 {
    font-size: 24px;
    line-height: 30px;
    text-transform: uppercase;
    font-family: "GeologicaRegular";
}

p {
    font-size: 18px;
    line-height: 28px;
    font-family: "Noto Sans";

    &.lead {
        font-size: 20px;
        line-height: 30px;
    }

    &.small {
        font-size: 16px;
        line-height: 22px;
    }
}

// XL
@include media-breakpoint-down(xxl) {
    //
}

// LG
@include media-breakpoint-down(xl) {
    h1 {
        font-size: 40px;
        line-height: 48px;
        text-transform: none;
        font-family: "GeologicaLight";

        &.lead {
            font-size: 48px;
            line-height: 56px;
            font-family: "GeologicaThin";
        }
    }

    h2 {
        font-size: 36px;
        line-height: normal;
        font-family: "GeologicaRegular";
    }

    h3 {
        font-size: 32px;
        line-height: normal;
        font-family: "GeologicaMedium";
    }

    h4 {
        font-size: 24px;
        line-height: 32px;
        font-family: "GeologicaMedium";
    }

    h5 {
        font-size: 24px;
        line-height: 28px;
        font-family: "GeologicaRegular";
    }

    p {
        font-size: 18px;
        line-height: normal;

        &.lead {
            font-size: 20px;
            line-height: 30px;
        }

        &.small {
            font-size: 16px;
            line-height: 24px;
        }
    }
}

// MD
@include media-breakpoint-down(lg) {
    //
}

// SM
@include media-breakpoint-down(md) {
    h1 {
        font-size: 28px;
        line-height: 34px;
        text-transform: none;
        font-family: "GeologicaRegular";

        &.lead {
            font-size: 36px;
            line-height: 42px;
            font-family: "GeologicaLight";
        }
    }

    h2 {
        font-size: 23px;
        line-height: 30px;
        font-family: "GeologicaMedium";
    }

    h3 {
        font-size: 20px;
        line-height: 28px;
        font-family: "GeologicaRegular";
    }

    h4 {
        font-size: 20px;
        line-height: 28px;
        font-family: "GeologicaRegular";
    }

    h5 {
        font-size: 24px;
        line-height: 28px;
        font-family: "GeologicaRegular";
    }

    p {
        font-size: 16px;
        line-height: 22px;

        &.lead {
            font-size: 17px;
            line-height: 24px;
        }

        &.small {
            font-size: 16px;
            line-height: 22px;
        }
    }
}

// XS
@include media-breakpoint-down(sm) {
    //
}
