.section-testemonial {
    .icon {
        width: 25px;
        height: 20px;
    }

    img {
        width: 112px;
        height: 112px;
    }

    .card {
        max-width: 395px;
    }

    .swiper:before {
        box-shadow:
            0 16px 0px 0px #ffffff,
            0px 16px 0px 0px #ffffff inset,
            12px 0 20px -4px white inset,
            -12px 0 20px 4px white inset;
        content: "";
        position: absolute;
        top: -16px;
        right: 0;
        left: 0;
        bottom: -8px;
        pointer-events: none;
        z-index: 10;
    }

    .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 12px;
    }

    .swiper-pagination-bullet {
        border: 4px solid #87a0bf;
        width: 18px;
        height: 18px;
        background: transparent;
        opacity: 1;

        &.swiper-pagination-bullet-active {
            opacity: 1;
            background: $primary-color-100;
        }
    }

    a {
        outline: none;
    }

    .swiper-slide {
        justify-content: start;
    }
}

//
// Breakpoints
// XXL (default)

// XL
@include media-breakpoint-down(xxl) {
    .section-testemonial {
        .card {
            max-width: 327px;
        }
    }
}

// LG
@include media-breakpoint-down(xl) {
    .section-testemonial {
        img {
            width: 164px;
            height: 164px;
        }

        .card {
            max-width: 640px;
            // min-width: 400px;

            &.border-primary-color-600 {
                border-color: transparent !important;
            }

            .border-right {
                border-right: 1px solid #e5e5e5 !important;
            }
        }
    }
}

// MD
@include media-breakpoint-down(lg) {
    .section-testemonial {
        .card {
            max-width: 100%;
        }
    }
}

// SM
@include media-breakpoint-down(md) {
    .section-testemonial {
        .card {
            max-width: 100%;
        }
    }
}

// XS
@include media-breakpoint-down(sm) {
    .section-testemonial {
        .card {
            max-width: 100%;
        }
    }
}
