.section-partners {
    p {
        margin-bottom: 0;
    }

    img {
        max-width: 160px;
        border-radius: 10px;
        box-shadow: $shadow-4;

        transition: box-shadow 300ms ease-in-out;

        &:hover {
            box-shadow: $shadow-3 !important;
        }
    }
}

// XL
@include media-breakpoint-down(xxl) {
    //
}

// LG
@include media-breakpoint-down(xl) {
    //
}

// MD
@include media-breakpoint-down(lg) {
    //
}

// SM
@include media-breakpoint-down(md) {
    .section-partners {
        img {
            max-width: 105px;
        }
    }
}

// XS
@include media-breakpoint-down(sm) {
    //
}
