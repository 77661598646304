// Primary colors
$primary-color-100: #0a2b5c;
$primary-color-200: #00347a;
$primary-color-250: #275086;
$primary-color-300: #435060;
$primary-color-400: #6f7a90;
$primary-color-500: #2b80ff;
$primary-color-600: #89a2c1;
$primary-color-700: #e0ecff;
$primary-color-800: #fafbff;
$primary-color-900: #fdfeff;

// Secondary colors
$secondary-color-200: #6c4b57;
$secondary-color-300: #ad003d;
$secondary-color-500: #fc5c94;
$secondary-color-600: #f7cada;
$secondary-color-700: #ffe0eb;

// Tertiary colors
$tertiary-color-200: #544b6c;
$tertiary-color-300: #6b2da8;
$tertiary-color-400: #e4d0f7;
$tertiary-color-700: #f0e0ff;

// Quaternary colors
$quaternary-color-700: #e0e1ff;

// Base colors
$gray-color: #e8eff0;
$white-color: #ffffff;
$black-color: #011632;
$orange-color: #ff5e03;
$stroke-color: #cadaf1;
