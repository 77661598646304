/* Scene wrapper */
.scene-wrapper {
    right: 38%;
    z-index: 1;
    top: 100px;
    position: absolute;
}

/* Phone element */
.phone-element {
    top: -50px;
}

.phone-slider {
    max-width: 244px !important;
    position: absolute;
    top: 30px;
    height: auto !important;
    left: 29px;
    z-index: -1;
    transform: scale(1.01);
}

.phone-frame {
    width: 430px;
    height: 686px;
    background: url(../images/hero/phone_frame.png);
    background-repeat: no-repeat;
    background-size: contain;
    // position: relative;
}

/* Tablet element */
.tablet-element {
    top: 150px;
    left: 350px;
}

.tablet-slider {
    max-width: 814px !important;
    position: absolute;
    top: 22px;
    height: auto !important;
    left: 27px;
    z-index: -1;
    transform: scale(1.01);
}

.tablet-frame {
    width: 950px;
    height: 702px;
    background: url(../images/hero/tablet_frame.png);
    background-repeat: no-repeat;
    background-size: contain;
    // position: relative;
}

/* Glasses element */
.glasses-element {
    top: -230px;
    left: 340px;
    width: 486px;
    height: auto;
    z-index: -5;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

/* Left blue background */
.blue-square-left {
    background-color: #ebf4ff;
    position: absolute;
    left: -1vw;
    top: 70vh;
    width: 60vw;
    height: 100vh;
    z-index: -1;
}

/* Right blue background */
.blue-square-right {
    background-color: #ebf4ff;
    position: absolute;
    right: calc(100% - 100vw - 56%);
    top: -350px;
    width: 1450px;
    height: 1850px;
    z-index: -1;
}

// Define variables
// $scene-wrapper-right: (
//     2200px: 40%,
//     2100px: 38.5%,
//     2000px: 37.5%,
//     1900px: 35%,
//     1800px: 36.5%,
//     1700px: 33.5%,
//     1600px: 30%,
//     1500px: 35%,
//     1400px: 32%,
//     1300px: 28%,
//     1200px: 28%,
//     1100px: 28%,
//     1025px: 26%
// );

// $blue-square-right: (
//     2200px: calc(100% - 100vw - 49%),
//     2100px: calc(100% - 100vw - 53%),
//     2000px: calc(100% - 100vw - 57%),
//     1900px: calc(100% - 100vw - 64%),
//     1800px: calc(100% - 100vw - 68%),
//     1700px: calc(100% - 100vw - 78%),
//     1600px: calc(100% - 100vw - 88%),
//     1500px: calc(100% - 100vw - 91%),
//     1400px: calc(100% - 100vw - 102%),
//     1300px: calc(100% - 100vw - 116.5%),
//     1200px: calc(100% - 100vw - 128%),
//     1100px: calc(100% - 100vw - 142.5%),
//     1025px: calc(100% - 100vw - 158%)
// );
// Define variables
$scene-wrapper-right: (
    3000px: 40%,
    2600px: 40%,
    2500px: 40%,
    2400px: 40%,
    2300px: 40%,
    2200px: 40%,
    2175px: 39.5%,
    2150px: 39%,
    2125px: 38.75%,
    2100px: 38.5%,
    2075px: 38%,
    2050px: 37.75%,
    2025px: 37.5%,
    2000px: 37.5%,
    1975px: 37.25%,
    1950px: 37%,
    1925px: 36.75%,
    1900px: 35%,
    1875px: 35%,
    1850px: 34.75%,
    1825px: 34.5%,
    1800px: 36.5%,
    1775px: 36.25%,
    1750px: 36%,
    1725px: 35.75%,
    1700px: 33.5%,
    1675px: 33.25%,
    1650px: 33%,
    1625px: 32.75%,
    1600px: 30%,
    1575px: 29.75%,
    1550px: 29.5%,
    1525px: 29.25%,
    1500px: 35%,
    1475px: 34.75%,
    1450px: 34.5%,
    1425px: 34.25%,
    1400px: 32%,
    1375px: 31.75%,
    1350px: 31.5%,
    1325px: 31.25%,
    1300px: 28%,
    1275px: 27.75%,
    1250px: 27.5%,
    1225px: 27.25%,
    1200px: 28%,
    1175px: 27.75%,
    1150px: 27.5%,
    1125px: 27.25%,
    1100px: 28%,
    1075px: 27.75%,
    1050px: 27.5%,
    1025px: 26%
);

$blue-square-right: (
    3000px: calc(100% - 100vw - 26%),
    2900px: calc(100% - 100vw - 28%),
    2800px: calc(100% - 100vw - 30%),
    2700px: calc(100% - 100vw - 32%),
    2600px: calc(100% - 100vw - 34%),
    2500px: calc(100% - 100vw - 36%),
    2400px: calc(100% - 100vw - 38%),
    2300px: calc(100% - 100vw - 44%),
    2200px: calc(100% - 100vw - 45%),
    2175px: calc(100% - 100vw - 50.75%),
    2150px: calc(100% - 100vw - 52.5%),
    2125px: calc(100% - 100vw - 54.25%),
    2100px: calc(100% - 100vw - 53%),
    2075px: calc(100% - 100vw - 53.75%),
    2050px: calc(100% - 100vw - 54.5%),
    2025px: calc(100% - 100vw - 55.25%),
    2000px: calc(100% - 100vw - 57%),
    1975px: calc(100% - 100vw - 58.75%),
    1950px: calc(100% - 100vw - 60.5%),
    1925px: calc(100% - 100vw - 62.25%),
    1900px: calc(100% - 100vw - 64%),
    1875px: calc(100% - 100vw - 65.75%),
    1850px: calc(100% - 100vw - 67.5%),
    1825px: calc(100% - 100vw - 69.25%),
    1800px: calc(100% - 100vw - 68%),
    1775px: calc(100% - 100vw - 69.75%),
    1750px: calc(100% - 100vw - 71.5%),
    1725px: calc(100% - 100vw - 73.25%),
    1700px: calc(100% - 100vw - 78%),
    1675px: calc(100% - 100vw - 79.75%),
    1650px: calc(100% - 100vw - 81.5%),
    1625px: calc(100% - 100vw - 83.25%),
    1600px: calc(100% - 100vw - 88%),
    1575px: calc(100% - 100vw - 89.75%),
    1550px: calc(100% - 100vw - 91.5%),
    1525px: calc(100% - 100vw - 93.25%),
    1500px: calc(100% - 100vw - 91%),
    1475px: calc(100% - 100vw - 92.75%),
    1450px: calc(100% - 100vw - 94.5%),
    1425px: calc(100% - 100vw - 96.25%),
    1400px: calc(100% - 100vw - 102%),
    1375px: calc(100% - 100vw - 103.75%),
    1350px: calc(100% - 100vw - 105.5%),
    1325px: calc(100% - 100vw - 107.25%),
    1300px: calc(100% - 100vw - 116.5%),
    1275px: calc(100% - 100vw - 118.25%),
    1250px: calc(100% - 100vw - 120%),
    1225px: calc(100% - 100vw - 121.75%),
    1200px: calc(100% - 100vw - 128%),
    1175px: calc(100% - 100vw - 129.75%),
    1150px: calc(100% - 100vw - 131.5%),
    1125px: calc(100% - 100vw - 133.25%),
    1100px: calc(100% - 100vw - 142.5%),
    1075px: calc(100% - 100vw - 153.25%),
    1050px: calc(100% - 100vw - 160%),
    1025px: calc(100% - 100vw - 158%)
);

// Define mixins
@mixin media-query($breakpoint) {
    @media screen and (max-width: #{$breakpoint}) {
        @content;
    }
}

// Scene wrapper styles
.scene-wrapper {
    @each $breakpoint, $value in $scene-wrapper-right {
        @include media-query($breakpoint) {
            right: $value;
        }
    }
}

// Blue square styles
.blue-square-right {
    @each $breakpoint, $value in $blue-square-right {
        @include media-query($breakpoint) {
            right: $value;
        }
    }
}

// XL
@include media-breakpoint-down(xxl) {
    //
}

// LG
@include media-breakpoint-down(xl) {
    .blue-square-right {
        display: none;
    }

    .blue-square-left {
        display: none;
    }
}

// MD
@include media-breakpoint-down(lg) {
    //
}

// SM
@include media-breakpoint-down(md) {
    //
}

// XS
@include media-breakpoint-down(sm) {
    //
}
