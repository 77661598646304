header {
    z-index: 10;
    position: sticky;
    top: 0;

    .container {
        border-radius: 0 0 10px 10px;
        box-shadow: 0 0 28px rgb(0 0 0 / 10%);
        max-width: 1500px;

        .logo {
            padding: 16px 0;
        }

        .colorful-logo-icon {
            width: 218px;
            height: 37px;
        }

        .menu {
            position: relative;

            .menu-gauge {
                position: absolute;
                top: 0;
                width: 100%;
                height: 6px;
                background-color: $secondary-color-700;

                .menu-gauge-inner {
                    background: $secondary-color-500;
                    width: 122px;
                    height: 6px;
                    left: 34%;
                    position: absolute;
                }
            }

            ul {
                list-style: none;
                margin: 0;
                display: flex;
                flex-direction: row;

                li {
                    a {
                        text-decoration: none;
                        color: $primary-color-100;
                        font-family: "Noto Sans";
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }

        .phone {
            a {
                font-family: "GeologicaSemiBold";
                font-size: 22px;
                line-height: 28px;
                text-decoration: none;
                color: $primary-color-100;
            }
        }
    }

    .navbar-toggler {
        &:focus {
            border: medium none !important;
            box-shadow: none !important;
        }

        .navbar-toggler-icon {
            width: 32px;
            height: 32px;
            background-image: url(data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiAgd2lkdGg9IjI0IiAgaGVpZ2h0PSIyNCIgIHZpZXdCb3g9IjAgMCAyNCAyNCIgIGZpbGw9Im5vbmUiICBzdHJva2U9ImN1cnJlbnRDb2xvciIgIHN0cm9rZS13aWR0aD0iMiIgIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgIHN0cm9rZS1saW5lam9pbj0icm91bmQiICBjbGFzcz0iaWNvbiBpY29uLXRhYmxlciBpY29ucy10YWJsZXItb3V0bGluZSBpY29uLXRhYmxlci1tZW51LTIiPjxwYXRoIHN0cm9rZT0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjxwYXRoIGQ9Ik00IDZsMTYgMCIgLz48cGF0aCBkPSJNNCAxMmwxNiAwIiAvPjxwYXRoIGQ9Ik00IDE4bDE2IDAiIC8+PC9zdmc+);
        }
    }

    .collapse {
        .nav-link {
            padding: 18px 20px 24px !important;
            border-top: 6px solid #ffe0eb;

            &.active {
                border-color: #fc5c94;
            }
        }
    }
}

@media (min-width: 1400px) and (max-width: 1500px) {
    header {
        .container {
            border-radius: 0;
        }
    }
}

// XL
@include media-breakpoint-down(xxl) {
    header {
        .container {
            max-width: 1125px;
        }

        .collapse {
            .nav-link {
                padding: 18px 13px 24px !important;
            }
        }
    }
}

@media (max-width: 1125px) {
    header {
        .container {
            border-radius: 0;
        }
    }
}

// LG
@include media-breakpoint-down(xl) {
    header {
        .container {
            border-radius: 0;
        }
    }
}

// MD
@include media-breakpoint-down(lg) {
    //
}

// SM
@include media-breakpoint-down(md) {
    //
}

// XS
@include media-breakpoint-down(sm) {
    //
}
