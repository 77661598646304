.section-faq {
    h4,
    p {
        margin-bottom: 0;
    }

    .icon {
        width: 32px;
        height: 32px;

        &:hover {
            cursor: pointer;
        }
    }

    .accordion {
        transition:
            border-color 300ms ease-in-out,
            box-shadow 300ms ease-in-out;

        &:hover {
            border-color: $primary-color-600 !important;
            box-shadow: $shadow-3 !important;
            cursor: pointer;
        }

        .icon {
            transition: background-color 300ms ease-in-out;
            border-radius: 50px;

            &:hover {
                background-color: $gray-color;
                background-size: 60px;
            }
        }

        .icon-plus {
            display: block;
        }

        .icon-minus {
            display: none;
        }

        .wrapper {
            display: grid;
            grid-template-rows: 0fr;
            overflow: hidden;
            transition: grid-template-rows 300ms ease-in-out;

            .description {
                opacity: 0;
                min-height: 0;
                transition: opacity 300ms ease-in-out;
            }
        }

        &.accordion-opened {
            &:hover {
                cursor: auto;
            }

            .icon-plus {
                display: none;
            }

            .icon-minus {
                display: block;
            }

            .wrapper {
                grid-template-rows: 1fr;

                .description {
                    opacity: 1;
                }
            }
        }
    }
}

// XL
@include media-breakpoint-down(xxl) {
    //
}

// LG
@include media-breakpoint-down(xl) {
    //
}

// MD
@include media-breakpoint-down(lg) {
    //
}

// SM
@include media-breakpoint-down(md) {
    //
}

// XS
@include media-breakpoint-down(sm) {
    //
}
