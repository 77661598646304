@font-face {
    font-family: "GeologicaMedium";
    src: url("/fonts/geologica/Geologica-Medium.eot");
    src:
        local("Geologica Medium"),
        local("Geologica-Medium"),
        url("/fonts/geologica/Geologica-Medium.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/Geologica-Medium.woff2") format("woff2"),
        url("/fonts/geologica/Geologica-Medium.woff") format("woff"),
        url("/fonts/geologica/Geologica-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaCursiveLight";
    src: url("/fonts/geologica/GeologicaCursive-Light.eot");
    src:
        local("Geologica Cursive Light"),
        local("GeologicaCursive-Light"),
        url("/fonts/geologica/GeologicaCursive-Light.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/GeologicaCursive-Light.woff2") format("woff2"),
        url("/fonts/geologica/GeologicaCursive-Light.woff") format("woff"),
        url("/fonts/geologica/GeologicaCursive-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaBold";
    src: url("/fonts/geologica/Geologica-Bold.eot");
    src:
        local("Geologica Bold"),
        local("Geologica-Bold"),
        url("/fonts/geologica/Geologica-Bold.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/Geologica-Bold.woff2") format("woff2"),
        url("/fonts/geologica/Geologica-Bold.woff") format("woff"),
        url("/fonts/geologica/Geologica-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaCursiveBlack";
    src: url("/fonts/geologica/GeologicaCursive-Black.eot");
    src:
        local("Geologica Cursive Black"),
        local("GeologicaCursive-Black"),
        url("/fonts/geologica/GeologicaCursive-Black.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/GeologicaCursive-Black.woff2") format("woff2"),
        url("/fonts/geologica/GeologicaCursive-Black.woff") format("woff"),
        url("/fonts/geologica/GeologicaCursive-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaExtraBold";
    src: url("/fonts/geologica/Geologica-ExtraBold.eot");
    src:
        local("Geologica ExtraBold"),
        local("Geologica-ExtraBold"),
        url("/fonts/geologica/Geologica-ExtraBold.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/Geologica-ExtraBold.woff2") format("woff2"),
        url("/fonts/geologica/Geologica-ExtraBold.woff") format("woff"),
        url("/fonts/geologica/Geologica-ExtraBold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaThin";
    src: url("/fonts/geologica/Geologica-Thin.eot");
    src:
        local("Geologica Thin"),
        local("Geologica-Thin"),
        url("/fonts/geologica/Geologica-Thin.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/Geologica-Thin.woff2") format("woff2"),
        url("/fonts/geologica/Geologica-Thin.woff") format("woff"),
        url("/fonts/geologica/Geologica-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaCursiveExtraLight";
    src: url("/fonts/geologica/GeologicaCursive-ExtraLight.eot");
    src:
        local("Geologica Cursive ExtraLight"),
        local("GeologicaCursive-ExtraLight"),
        url("/fonts/geologica/GeologicaCursive-ExtraLight.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/GeologicaCursive-ExtraLight.woff2") format("woff2"),
        url("/fonts/geologica/GeologicaCursive-ExtraLight.woff") format("woff"),
        url("/fonts/geologica/GeologicaCursive-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaExtraLight";
    src: url("/fonts/geologica/Geologica-ExtraLight.eot");
    src:
        local("Geologica ExtraLight"),
        local("Geologica-ExtraLight"),
        url("/fonts/geologica/Geologica-ExtraLight.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/Geologica-ExtraLight.woff2") format("woff2"),
        url("/fonts/geologica/Geologica-ExtraLight.woff") format("woff"),
        url("/fonts/geologica/Geologica-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaCursiveBold";
    src: url("/fonts/geologica/GeologicaCursive-Bold.eot");
    src:
        local("Geologica Cursive Bold"),
        local("GeologicaCursive-Bold"),
        url("/fonts/geologica/GeologicaCursive-Bold.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/GeologicaCursive-Bold.woff2") format("woff2"),
        url("/fonts/geologica/GeologicaCursive-Bold.woff") format("woff"),
        url("/fonts/geologica/GeologicaCursive-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaLight";
    src: url("/fonts/geologica/Geologica-Light.eot");
    src:
        local("Geologica Light"),
        local("Geologica-Light"),
        url("/fonts/geologica/Geologica-Light.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/Geologica-Light.woff2") format("woff2"),
        url("/fonts/geologica/Geologica-Light.woff") format("woff"),
        url("/fonts/geologica/Geologica-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaCursiveMedium";
    src: url("/fonts/geologica/GeologicaCursive-Medium.eot");
    src:
        local("Geologica Cursive Medium"),
        local("GeologicaCursive-Medium"),
        url("/fonts/geologica/GeologicaCursive-Medium.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/GeologicaCursive-Medium.woff2") format("woff2"),
        url("/fonts/geologica/GeologicaCursive-Medium.woff") format("woff"),
        url("/fonts/geologica/GeologicaCursive-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaSemiBold";
    src: url("/fonts/geologica/Geologica-SemiBold.eot");
    src:
        local("Geologica SemiBold"),
        local("Geologica-SemiBold"),
        url("/fonts/geologica/Geologica-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/Geologica-SemiBold.woff2") format("woff2"),
        url("/fonts/geologica/Geologica-SemiBold.woff") format("woff"),
        url("/fonts/geologica/Geologica-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaCursiveExtraBold";
    src: url("/fonts/geologica/GeologicaCursive-ExtraBold.eot");
    src:
        local("Geologica Cursive ExtraBold"),
        local("GeologicaCursive-ExtraBold"),
        url("/fonts/geologica/GeologicaCursive-ExtraBold.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/GeologicaCursive-ExtraBold.woff2") format("woff2"),
        url("/fonts/geologica/GeologicaCursive-ExtraBold.woff") format("woff"),
        url("/fonts/geologica/GeologicaCursive-ExtraBold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaBlack";
    src: url("/fonts/geologica/Geologica-Black.eot");
    src:
        local("Geologica Black"),
        local("Geologica-Black"),
        url("/fonts/geologica/Geologica-Black.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/Geologica-Black.woff2") format("woff2"),
        url("/fonts/geologica/Geologica-Black.woff") format("woff"),
        url("/fonts/geologica/Geologica-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaRegular";
    src: url("/fonts/geologica/Geologica-Regular.eot");
    src:
        local("Geologica Regular"),
        local("Geologica-Regular"),
        url("/fonts/geologica/Geologica-Regular.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/Geologica-Regular.woff2") format("woff2"),
        url("/fonts/geologica/Geologica-Regular.woff") format("woff"),
        url("/fonts/geologica/Geologica-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaRomanBlack";
    src: url("/fonts/geologica/GeologicaRoman-Black.eot");
    src:
        local("Geologica Roman Black"),
        local("GeologicaRoman-Black"),
        url("/fonts/geologica/GeologicaRoman-Black.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/GeologicaRoman-Black.woff2") format("woff2"),
        url("/fonts/geologica/GeologicaRoman-Black.woff") format("woff"),
        url("/fonts/geologica/GeologicaRoman-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaRomanMedium";
    src: url("/fonts/geologica/GeologicaRoman-Medium.eot");
    src:
        local("Geologica Roman Medium"),
        local("GeologicaRoman-Medium"),
        url("/fonts/geologica/GeologicaRoman-Medium.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/GeologicaRoman-Medium.woff2") format("woff2"),
        url("/fonts/geologica/GeologicaRoman-Medium.woff") format("woff"),
        url("/fonts/geologica/GeologicaRoman-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaRomanExtraLight";
    src: url("/fonts/geologica/GeologicaRoman-ExtraLight.eot");
    src:
        local("Geologica Roman ExtraLight"),
        local("GeologicaRoman-ExtraLight"),
        url("/fonts/geologica/GeologicaRoman-ExtraLight.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/GeologicaRoman-ExtraLight.woff2") format("woff2"),
        url("/fonts/geologica/GeologicaRoman-ExtraLight.woff") format("woff"),
        url("/fonts/geologica/GeologicaRoman-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaRomanSemmiBold";
    src: url("/fonts/geologica/GeologicaRoman-SemiBold.eot");
    src:
        local("Geologica Roman SemmiBold"),
        local("GeologicaRoman-SemiBold"),
        url("/fonts/geologica/GeologicaRoman-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/GeologicaRoman-SemiBold.woff2") format("woff2"),
        url("/fonts/geologica/GeologicaRoman-SemiBold.woff") format("woff"),
        url("/fonts/geologica/GeologicaRoman-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaRomanRegular";
    src: url("/fonts/geologica/GeologicaRoman-Regular.eot");
    src:
        local("Geologica Roman Regular"),
        local("GeologicaRoman-Regular"),
        url("/fonts/geologica/GeologicaRoman-Regular.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/GeologicaRoman-Regular.woff2") format("woff2"),
        url("/fonts/geologica/GeologicaRoman-Regular.woff") format("woff"),
        url("/fonts/geologica/GeologicaRoman-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaRomanExtraBold";
    src: url("/fonts/geologica/GeologicaRoman-ExtraBold.eot");
    src:
        local("Geologica Roman ExtraBold"),
        local("GeologicaRoman-ExtraBold"),
        url("/fonts/geologica/GeologicaRoman-ExtraBold.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/GeologicaRoman-ExtraBold.woff2") format("woff2"),
        url("/fonts/geologica/GeologicaRoman-ExtraBold.woff") format("woff"),
        url("/fonts/geologica/GeologicaRoman-ExtraBold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaCursiveThin";
    src: url("/fonts/geologica/GeologicaCursive-Thin.eot");
    src:
        local("Geologica Cursive Thin"),
        local("GeologicaCursive-Thin"),
        url("/fonts/geologica/GeologicaCursive-Thin.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/GeologicaCursive-Thin.woff2") format("woff2"),
        url("/fonts/geologica/GeologicaCursive-Thin.woff") format("woff"),
        url("/fonts/geologica/GeologicaCursive-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaCursiveRegular";
    src: url("/fonts/geologica/GeologicaCursive-Regular.eot");
    src:
        local("Geologica Cursive Regular"),
        local("GeologicaCursive-Regular"),
        url("/fonts/geologica/GeologicaCursive-Regular.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/GeologicaCursive-Regular.woff2") format("woff2"),
        url("/fonts/geologica/GeologicaCursive-Regular.woff") format("woff"),
        url("/fonts/geologica/GeologicaCursive-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaRomanThin";
    src: url("/fonts/geologica/GeologicaRoman-Thin.eot");
    src:
        local("Geologica Roman Thin"),
        local("GeologicaRoman-Thin"),
        url("/fonts/geologica/GeologicaRoman-Thin.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/GeologicaRoman-Thin.woff2") format("woff2"),
        url("/fonts/geologica/GeologicaRoman-Thin.woff") format("woff"),
        url("/fonts/geologica/GeologicaRoman-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaRomanBold";
    src: url("/fonts/geologica/GeologicaRoman-Bold.eot");
    src:
        local("Geologica Roman Bold"),
        local("GeologicaRoman-Bold"),
        url("/fonts/geologica/GeologicaRoman-Bold.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/GeologicaRoman-Bold.woff2") format("woff2"),
        url("/fonts/geologica/GeologicaRoman-Bold.woff") format("woff"),
        url("/fonts/geologica/GeologicaRoman-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaRomanLight";
    src: url("/fonts/geologica/GeologicaRoman-Light.eot");
    src:
        local("Geologica Roman Light"),
        local("GeologicaRoman-Light"),
        url("/fonts/geologica/GeologicaRoman-Light.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/GeologicaRoman-Light.woff2") format("woff2"),
        url("/fonts/geologica/GeologicaRoman-Light.woff") format("woff"),
        url("/fonts/geologica/GeologicaRoman-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GeologicaCursiveSemiBold";
    src: url("/fonts/geologica/GeologicaCursive-SemiBold.eot");
    src:
        local("Geologica Cursive SemiBold"),
        local("GeologicaCursive-SemiBold"),
        url("/fonts/geologica/GeologicaCursive-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("/fonts/geologica/GeologicaCursive-SemiBold.woff2") format("woff2"),
        url("/fonts/geologica/GeologicaCursive-SemiBold.woff") format("woff"),
        url("/fonts/geologica/GeologicaCursive-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
