.section-about {
    .company-name-icon {
        max-width: 987px;
        width: 100%;
        height: auto;
    }
}

// XL
@include media-breakpoint-down(xxl) {
    .section-about {
        .company-name-icon {
            max-width: 856px;
        }
    }
}

// LG
@include media-breakpoint-down(xl) {
    .section-about {
        .company-name-icon {
            max-width: 680px;
        }
    }
}

// MD
@include media-breakpoint-down(lg) {
    //
}

// SM
@include media-breakpoint-down(md) {
    //
}

// XS
@include media-breakpoint-down(sm) {
    //
}
