@font-face {
    font-family: "Noto Sans";
    src: url("/fonts/notosans/NotoSans-Bold.eot");
    src:
        local("Noto Sans Bold"),
        local("NotoSans-Bold"),
        url("/fonts/notosans/NotoSans-Bold.eot?#iefix") format("embedded-opentype"),
        url("/fonts/notosans/NotoSans-Bold.woff2") format("woff2"),
        url("/fonts/notosans/NotoSans-Bold.woff") format("woff"),
        url("/fonts/notosans/NotoSans-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Noto Sans";
    src: url("/fonts/notosans/NotoSans-Italic.eot");
    src:
        local("Noto Sans Italic"),
        local("NotoSans-Italic"),
        url("/fonts/notosans/NotoSans-Italic.eot?#iefix") format("embedded-opentype"),
        url("/fonts/notosans/NotoSans-Italic.woff2") format("woff2"),
        url("/fonts/notosans/NotoSans-Italic.woff") format("woff"),
        url("/fonts/notosans/NotoSans-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Noto Sans";
    src: url("/fonts/notosans/NotoSans.eot");
    src:
        local("Noto Sans"),
        local("NotoSans"),
        url("/fonts/notosans/NotoSans.eot?#iefix") format("embedded-opentype"),
        url("/fonts/notosans/NotoSans.woff2") format("woff2"),
        url("/fonts/notosans/NotoSans.woff") format("woff"),
        url("/fonts/notosans/NotoSans.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Noto Sans";
    src: url("/fonts/notosans/NotoSans-BoldItalic.eot");
    src:
        local("Noto Sans Bold Italic"),
        local("NotoSans-BoldItalic"),
        url("/fonts/notosans/NotoSans-BoldItalic.eot?#iefix") format("embedded-opentype"),
        url("/fonts/notosans/NotoSans-BoldItalic.woff2") format("woff2"),
        url("/fonts/notosans/NotoSans-BoldItalic.woff") format("woff"),
        url("/fonts/notosans/NotoSans-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}
