.section-why {
    .icon {
        width: 96px;
        height: 96px;
    }

    .card-1 {
        width: 65%;
    }

    .card-2 {
        width: 35%;
        // padding-right: 0.75rem;
    }

    .card-3 {
        width: 35%;
        // padding-left: 0.75rem;
    }

    .card-4 {
        width: 65%;
    }

    .card-1,
    .card-2,
    .card-3,
    .card-4 {
        > div {
            height: 100%;
            min-height: 378px;
        }
    }

    .card-1 > div {
        background-image: url("/images/why/woman-v2.png");
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: contain;
    }

    .card-4 > div {
        background-image: url("/images/why/phone-v2.png");
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: contain;
    }
}

// XL
@include media-breakpoint-down(xxl) {
    .section-why {
        .card-1,
        .card-4 {
            > div {
                background-position-x: 408px;
            }
        }

        .icon {
            width: 75px;
            height: 75px;
        }
    }
}

// LG
@include media-breakpoint-down(xl) {
    .section-why {
        .card-1 {
            width: 100%;
        }

        .card-2 {
            width: 50%;
        }

        .card-3 {
            width: 50%;
        }

        .card-4 {
            width: 100%;
        }

        .card-1,
        .card-4 {
            > div {
                background-position-x: right;
            }
        }

        .card-1,
        .card-2,
        .card-3,
        .card-4 {
            > div {
                height: 100%;
                min-height: 350px;
            }
        }
    }
}

// MD
@include media-breakpoint-down(lg) {
    .section-why {
        .card-4 > div {
            background-image: none;
        }

        .card-1,
        .card-2,
        .card-3,
        .card-4 {
            > div {
                min-height: auto;
            }
        }

        .card-1,
        .card-4 {
            > div {
                background-position-x: 468px;
            }
        }
    }
}

// SM
@include media-breakpoint-down(md) {
    .section-why {
        .card-2 {
            width: 100%;
        }

        .card-3 {
            width: 100%;
        }
    }
}

// XS
@include media-breakpoint-down(sm) {
    //
}
