.section-services {
    .icon {
        width: 76px;
        height: 76px;
    }

    .card {
        max-width: 375px;
        min-height: 450px;

        transition:
            border-color 300ms ease-in-out,
            box-shadow 300ms ease-in-out;

        &:hover {
            // border-color: $primary-color-600 !important;
            box-shadow: $shadow-3 !important;
        }
    }
}

// XL
@include media-breakpoint-down(xxl) {
    .section-services {
        .card {
            max-width: 100%;
            min-height: 410px;
        }
    }
}

// LG
@include media-breakpoint-down(xl) {
    .section-services {
        .card {
            min-height: 300px;
        }
    }
}

// MD
@include media-breakpoint-down(lg) {
    .section-services {
        .card {
            min-height: auto;
        }
    }
}

// SM
@include media-breakpoint-down(md) {
    //
}

// XS
@include media-breakpoint-down(sm) {
    //
}
