$shadow-1: 0 40px 100px rgb(0 0 0 / 15%);
$shadow-2: 64px 64px 100px rgb(0 0 0 / 17%);

$shadow-3: 0 22px 60px rgb(0 0 22 / 20%);
$shadow-3-darker: 0 22px 60px rgb(0 0 22 / 40%);

$shadow-4: 0px 12px 40px rgba(0, 0, 22, 0.1);

.shadow-1 {
    box-shadow: $shadow-1;
}

.shadow-2 {
    box-shadow: $shadow-2;
}

.shadow-3 {
    box-shadow: $shadow-3;
}

.shadow-3-darker {
    box-shadow: $shadow-3-darker;
}

.shadow-4 {
    box-shadow: $shadow-4;
}
