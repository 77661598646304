@import "figma-colors";

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y: 0.75rem !default;
$input-btn-padding-x: 1.375rem !default;
$input-btn-font-family: "GeologicaRegular" !default;
$input-btn-font-size: 1.15rem !default;
$input-btn-line-height: 1.5 !default;

$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-padding-x-sm: 0.5rem !default;
$input-btn-font-size-sm: 0.875 !default;

$input-btn-padding-y-lg: 0.875rem !default;
$input-btn-padding-x-lg: 2.125rem !default;
$input-btn-font-size-lg: 1.125 !default;

$input-btn-border-width: 2px !default;
// scss-docs-end input-btn-variables

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-color: $primary-color-200 !default;
$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x !default;
$btn-font-family: $input-btn-font-family !default;
$btn-font-size: $input-btn-font-size !default;
$btn-line-height: $input-btn-line-height !default;

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-font-size-sm: $input-btn-font-size-sm !default;

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: $input-btn-padding-x-lg !default;
$btn-font-size-lg: $input-btn-font-size-lg !default;

$btn-border-width: $input-btn-border-width !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 12px !default;
$btn-border-radius-sm: 8px !default;
$btn-border-radius-lg: 12px !default;
// scss-docs-end btn-variables
