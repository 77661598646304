// scss-docs-start grid-breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 361px,
    md: 559px,
    lg: 769px,
    xl: 1025px,
    xxl: 1441px
) !default;
// scss-docs-end grid-breakpoints

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
    sm: 480px,
    md: 680px,
    lg: 950px,
    xl: 1100px,
    xxl: 1400px
) !default;
// scss-docs-end container-max-widths
