.container-md {
    max-width: 948px;
}

.container-sm {
    max-width: 768px;
}

// XL
@include media-breakpoint-down(xxl) {
    //
}

// LG
@include media-breakpoint-down(xl) {
    //
}

// MD
@include media-breakpoint-down(lg) {
    .container-md,
    .container-sm {
        max-width: 680px;
    }
}

// SM
@include media-breakpoint-down(md) {
    .container-md,
    .container-sm {
        max-width: 480px;
    }
}

// XS
@include media-breakpoint-down(sm) {
    //
}
